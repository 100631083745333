import Vue from 'vue'
import { EagleFormConfigInterface } from '@/components/form/types/form'
import bankList from "@/config/bank.json"
import citiesList from "@/config/citiesList.json"

class formConfig {
  protected vm?: Vue
  protected formKey?: string

  _defaultData() {
    return {

    }
  }

  get(vm: Vue, formKey: string): EagleFormConfigInterface {
    this.vm = vm
    this.formKey = formKey

    return {
      pageTitle: (formMeta: any, formData: AnyObject) => window.eagleLodash.get(formData, ['name']),
      pageTitleBase: () => 'line-message-edit-provider',
      defaultData: this._defaultData,
      dataAction: {
        status: false,
        back: false,
        delete: false,
        refresh: false,
      },
      blocks: [
        {
          data: {
            'welcome_text': {
              label: '歡迎訊息',
              type: 'liff-textarea',
              maxlength: 200,
            },
          },
        },
        {
          data: {
            'contact': {
              label: '聯絡方式',
              type: 'liff-textarea',
              maxlength: 200,
            },
          },
        },
        {
          data: {
            'terms': {
              label: '服務條款',
              type: 'liff-textarea',
              maxlength: 1000,
            },
          },
        },
        {
          data: {
            'privacy': {
              label: '隱私權條款 ',
              type: 'liff-textarea',
              maxlength: 1000,
            },
          },
        },
      ],
    }
  }

}

export default new formConfig()
